export let orderStatus=[
    {
        name:"Processing",
        num:56
    },
    {
        name:"Delivered",
        num:30
    },
    {
        name:"Shipped",
        num:11
    }
]


export const stock=[
    {
        name:"In stock",
        num:95
    },
    {
        name:"Out of stock",
        num:5
    }
]